window.axios = require("axios");

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
$(document).ready(function () {
    $(".cpf").mask("000.000.000-00");
    $(".celular").mask("(00) 0 0000-0000");
    $(".cep").mask("00000-000");
    window.$("select").select2({
        theme: "bootstrap4",
        width: "100%",
    });
    $(".nav .nav-link").on("click", function () {
        $(".nav").find(".active").removeClass("active");
        $(this).addClass("active");
    });
});

$("#modal-vaga").modal("show");
$("#vacancy.nav-item>a.nav-link:first").addClass("active");
$(".tab-pane.fade:first").addClass("show active");

// $("#btn-next").on("click", function() {
//     location.href = `/inscricao/vaga/${$("#vaga").val()}`;
// });

$("input:radio[name=pcd]").change(function () {
    let id = $("input:hidden[name=subscription_id]")[0].value;
    window.axios.post(`api/v1/pcd/${id}`, { pcd: this.value }).then((response) => {
        console.info(response.body);
    });
});
